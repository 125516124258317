import React from "react";
import "../styles/Landing.css";

import logo from "../Assets/imgs/logo.webp";
import mainimg from "../Assets/imgs/main-text.webp";
import playerimg from "../Assets/imgs/player.webp";

type Props = {
  showLoader: boolean;
  errorMsg: string;
  showError: boolean;
  onSubscribePressed: () => void;
  showPlay: boolean;
  confirmLoader: boolean;
  phoneNo: string;
  onHandlePlay: () => void;
};

const Landing = (props: Props) => {
  return (
    <div className="landing-bg">
      <div className="subscrption-content">
        <div className="logo">
          <img src={logo} width={375} height={53} alt="Logo" />
        </div>

        <div className="main-image">
          <img src={mainimg} width={374} height={348} alt="Main image" />
        </div>

        <div className="p-text">
          then Only<br></br> <span>Rs. 10/Day</span> (incl of tax)
        </div>

        <div className="button">
          {props.showError && <p className="error">{props.errorMsg}</p>}
          {props.phoneNo && (
            <>
              {props.showPlay ? (
                <>
                  {props.confirmLoader ? (
                    <button className="disable-btn p-button">Confirm</button>
                  ) : (
                    <button
                      className="p-button"
                      onClick={() => props.onSubscribePressed()}
                    >
                      Confirm
                    </button>
                  )}
                </>
              ) : (
                <button className="p-button" onClick={props.onHandlePlay}>
                  PLAY NOW
                </button>
              )}
            </>
          )}
        </div>

        <div className="sub-text">
          I agree to daily recurring charges deduction from my mobile balance.
          Free trial is for new users only
        </div>

        <div className="terms">
          <a href="terms">TERMS & CONDITIONS</a>
        </div>
        <div className="palyer-img">
          <img src={playerimg} width={374} height={457} alt="Player Image" />
        </div>
      </div>
    </div>
  );
};

export default Landing;
